body {
  .cropper-view-box {
    display: block;
    height: 100%;
    outline: none;
    overflow: hidden;
    width: 100%;
  }

  .cropper-center {
    display: none;
  }

  .cropper-line {
    background-color: transparent;
  }

  .cropper-dashed {
    display: none;
  }

  .cropper-point {
    background-color: transparent;
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }

  .cropper-point.point-se::before {
    background-color: transparent;
    bottom: -50%;
    content: " ";
    display: block;
    //height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }
}
