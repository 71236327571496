@import "variables.module";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
}

.main {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.invitationHeader {
  width: 100%;
  height: 138px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &From {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    &Text {
      margin-left: 7px;
    }

    &Name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 220px;
      color: $color-white;
      font-size: 18px;
      font-weight: 700;
    }

    > div {
      margin: 0 8px;
    }
  }
}

.gridHeaderActions {
  display: flex;
  align-items: center;
  gap: 20px;

  .myNotification {
    position: relative;

    .myNotificationBadge {
      position: absolute;
      top: -5px;
      right: -5px;
      padding: 6px 6px;
      border-radius: 50%;
      background: $color-red-lighter;
    }
  }
}

.gridProfile {
  .profile {
    width: 100%;
    max-width: 390px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 40px 6px;

    .introduction {
      width: 200px;
      display: flex;
      flex-direction: column;

      .name {
        color: var(--black-black, #1d1d1d);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 8px;
      }

      .bio {
        color: var(--black-black, #1d1d1d);
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        overflow: hidden;
        word-break: break-all;
        max-height: 48px;
        margin-right: 20px;
        margin-bottom: 8px;
      }
    }
  }

  .followers {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .avatar {
        margin-right: -9px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    > span {
      font-size: 11px;
      font-weight: 600;
      margin-left: 4px;
    }
  }
}

.myHeader {
  width: 100%;
  max-width: $width-max;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  z-index: 1;

  .myActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120px;

    &.guest {
      width: 30px;
    }

    > * {
      position: relative;
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .myNotification {
      margin-top: 1px;
    }

    .myNotificationBadge {
      position: absolute;
      top: -5px;
      right: -5px;
      padding: 6px 6px;
      border-radius: 50%;
      background: $color-red-lighter;
    }

    .actionSelected {
      position: absolute;
      top: 34px;
      //left: 8px;
      //height: 10px;
      //width: 10px;
      //background: rgba(255, 255, 255, 0.9);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 12px solid rgba(255, 255, 255, 0.9);
      animation: fadeIn 0.3s linear;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-color: #000;
  z-index: -1;
  filter: brightness(70%);
}

.bgCover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
  &Image {
    background-color: $color-white;
    filter: brightness(70%);
  }
}
