@charset "UTF-8";

#lp {
  /* reset style */
  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  p,
  blockquote,
  th,
  td,
  input,
  select,
  textarea,
  button {
    margin: 0;
    padding: 0;
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    outline: none;
  }

  ul,
  li {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption,
  th {
    text-align: left;
  }

  figure {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
  }

  span {
    display: block;
  }

  /* all,html,body,layouts */
  * {
    font-size: 14px;
    color: #0f0f0f;
    box-sizing: border-box;

    font-weight: 400;
    font-feature-settings: "palt" 0;
    font-family: "Inter", "Noto Sans JP", "Helvetica Neue", Arial,
      "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    letter-spacing: -0.2px;
    line-height: 1;
  }

  /* font weight */
  .font_bold {
    font-weight: 600;
    font-style: normal;
  }

  .font_500 {
    font-weight: 500;
    font-style: normal;
  }

  .font_800 {
    font-weight: 800;
    font-style: normal;
  }

  /* text alignment */
  .align_center {
    text-align: center;
  }

  .align_right {
    text-align: right;
  }

  .align_left {
    text-align: left;
  }

  /* line height */
  .paragraph {
    line-height: 1.3;
  }

  .paragraph_ex {
    line-height: 1.6;
  }

  /* font_color */
  .font_gray {
    color: #9a9a9a;
  }

  .font_red {
    color: #F83838;
  }

  .font_white {
    color: #fff;
  }

  /* background color */
  .bg_black {
    background-color: #0f0f0f;
  }

  .bg_white {
    background-color: #fff;
  }

  .bg_blue {
    background-color: #000af7;
  }

  .bg_gray {
    background-color: #f8f8f8;
  }

  .bg_gray_out {
    background-color: rgba(255, 255, 255, 0.15);
  }

  /* radius */
  .radius_button {
    border-radius: 80px;
  }

  /* selection color */
  ::selection {
    background: #1d1d1d; /* Safari */
  }

  ::-moz-selection {
    background: #1d1d1d; /* Firefox */
  }

  /* clearfix */
  .clearfix:after {
    content: "";
    display: block;
    clear: both;
  }

  /* images and movies */
  img,
  canvas,
  iframe,
  video,
  svg {
    border: 0;
    width: 100%;
    max-width: 100%;
    display: block;
  }

  img {
    height: auto;
  }

  /* shadow */
  .shadow {
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.15);
  }

  .shadow_card {
    box-shadow: 0px 6px 20px 0px rgba(149, 157, 165, 0.24),
      -2px -2px 2px 0px rgba(224, 224, 224, 0.1);
  }

  /* input */
  input[type="search"],
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  select,
  textarea,
  button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
  }

  input,
  select,
  textarea {
    font-family: inherit;
    font-weight: inherit;
    outline: none;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: solid 1px #ccc;
    background-color: transparent;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  input[type="text"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="url"],
  input[type="email"],
  input[type="file"],
  input[type="image"],
  input[type="number"],
  input[type="range"],
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  select,
  textarea {
    display: block;
  }

  input[type="color"],
  input[type="file"],
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button {
    cursor: pointer;
  }

  input[type="color"],
  input[type="file"],
  input[type="image"] {
    border: none;
    background-color: transparent;
  }

  select {
    text-indent: 0.01px;
    text-overflow: "";
    background-position: 100% center;
    background-repeat: no-repeat;
    padding: 0 20px 0 10px;
  }

  select::-ms-expand {
    display: none;
  }

  textarea {
    overflow: auto;
    display: block;
    resize: vertical;
  }
}
