$sp-max: 414px;
$tab-min: 415px;
$pc-min: 940px;

$width-max: 600px;

$color-bg-white: rgba(255, 255, 255, 0.9);
$color-white: #ffffff;
$color-gray: #aaaaaa;
$color-gray-darker: #666666;
$color-gray-lighter-600: #444444;
$color-gray-lighter-400: #888888;
$color-gray-lighter-350: #dddddd;
$color-gray-lighter-320: #d9d9d9;
$color-gray-lighter-300: #d8d8d8;
$color-gray-lighter-200: #f8f8f8;
$color-gray-lighter: #eeeeee;
$color-black: #1d1d1d;
$color-pink: #eb3b70;
$color-red: #f83838;
$color-red-lighter: #f46262;
$color-blue: #242be7;
