@import "reset";
@import "variables.module";
@import "custom-quill.module";
@import "custom-react-colorful.module";
@import "custom-cropper-js.module";
@import "animation.module";
@import "form.module";

* {
  margin: 0;
  color: $color-black;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", "Noto Sans JP", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-feature-settings: "palt";
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
