@charset "UTF-8";

#lp {
  width: 100%;

  .relative {
    position: relative;
    z-index: 1;
  }

  :root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
  }

  .pc_background {
    background-image: url("../../../public/images/lp/pc_background.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 601px) {
    .pc_background {
      display: none;
    }
  }

  /*---------------------------------------------------------------*\
  $whole content
  \*---------------------------------------------------------------*/

  /* pcでのみテキスト情報配置 */
  .wrap_whole_content {
    width: 900px;
    height: 100vh;
    margin: auto;
    left: 0;
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #info_pc_view {
    width: calc(100% - 420px);
  }

  #info_pc_view .logo {
    width: 100%;
    padding: 0 20% 0 0;
  }

  @media screen and (max-width: 1080px) {
    .wrap_whole_content {
      width: 100%;
      max-width: inherit;
    }

    #info_pc_view {
      width: calc(100% - 420px);
      padding: 0 5%;
    }
    #info_pc_view .logo {
      max-width: 240px;
      margin: auto;
      padding: 0;
    }
  }

  @media screen and (max-width: 601px) {
    #info_pc_view {
      display: none;
    }
  }

  /* main frame */
  .wrap_main_frame {
    margin: 0 auto;
    width: 900px;
  }

  .main_frame {
    width: 420px;
    z-index: 10;
    overflow: clip;
    position: relative;
    margin-left: auto;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1080px) {
    .wrap_main_frame {
      width: 100%;
    }
  }

  @media screen and (max-width: 601px) {
    .main_frame {
      width: 100%;
      box-shadow: inherit;
    }
  }

  /*---------------------------------------------------------------*\
  $component - dscription card
  \*---------------------------------------------------------------*/

  /* each dscription card */
  .each_dscription {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(29, 29, 29, 0.03);
    margin: 0 0 18px;
    padding: 16px 12px;
  }

  .each_dscription:last-of-type {
    margin-bottom: 0;
  }

  /* each_dscription */
  .dscriber_info {
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dscriber_info > div {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
  }

  .dscriber_thumbnail {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 6px;
  }

  .dscriber_id {
    font-size: 11px;
    margin-top: 4px;
  }

  .dscriber_tag {
    font-size: 11px;
    padding: 6px 10px;
    border-radius: 40px;
  }

  .dscription {
    font-size: 14px;
    margin: 10px 0 16px;
  }

  .dummy_dscription {
    margin-bottom: 120px;
    margin-top: 10px;
  }

  .post_info {
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: space-between;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .post_info > div {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
  }

  .like_icon {
    background-image: url(../../../public/images/lp/icons/icon_heart.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    width: 18px;
    height: 18px;
  }

  .like_icon_empty {
    background-image: url(../../../public/images/lp/icons/icon_heart_empty.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
    width: 18px;
    height: 18px;
  }

  .like_count {
    font-size: 13px;
    margin-left: 8px;
  }

  .date_posted {
    font-size: 12px;
  }

  /*---------------------------------------------------------------*\
  $dscribe - fullscreen
  \*---------------------------------------------------------------*/

  #dscribe {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .micromodal-slide #dscribe {
    width: 420px;
    position: absolute;
    top: 0;
    right: calc((100% - 900px)/2);
  }

  @media screen and (max-width: 1080px) {
    .micromodal-slide #dscribe {
      right: 0;
    }
  }

  @media screen and (max-width: 601px) {
    .micromodal-slide #dscribe {
      width: 100%;
    }
  }

  #dscribe .background_01 {
    background-image: url("../../../public/images/lp/background_01.webp");
  }

  #dscribe .background_02 {
    background-image: url("../../../public/images/lp/background_02.webp");
  }

  #dscribe .background_03 {
    background-image: url("../../../public/images/lp/background_03.webp");
  }

  #dscribe .background_01,
  #dscribe .background_02,
  #dscribe .background_03 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  #dscribe .background_01:after,
  #dscribe .background_02:after,
  #dscribe .background_03:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 0;
    left: 0;
  }

  #dscribe .menu {
    position: absolute;
    top: 12px;
    right: 0;
    left: 0;
    margin: auto;
    width: 90%;
    z-index: 1;
  }

  #dscribe .progress_bar {
    display: flex;
    justify-content: center;
    gap: 4px;
  }

  #dscribe .each_bar {
    width: calc((100%) / 2);
    height: 2px;
    border-radius: 20px;
  }

  #dscribe .icon_close {
    width: 24px;
    height: 24px;
    background-image: url(../../../public/images/lp/icons/icon_close.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 16px 0 0 auto;
  }

  #dscribe .content {
    width: 90%;
    margin: 0 auto;
  }

  #dscribe .upper {
    display: flex;
    justify-content: space-between;
    margin: 56px 0 0 0;
  }

  #dscribe .user {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  #dscribe .logo {
    width: 152px;
    margin: 0 auto;
  }

  #dscribe .thumbnail {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #fff;
    background-color: #444;
    overflow: hidden;
  }

  #dscribe .name {
    font-size: 18px;
  }

  #dscribe .dots {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  #dscribe .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  #dscribe .wrap_dscription {
    overflow: scroll;
    height: 300px;
    margin: 32px 0 0 0;
    position: relative;
  }

  #dscribe .dscription {
    font-size: 20px;
    line-height: 1.4;
  }

  #dscribe .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 48px 0 0 0;
  }

  #dscribe .likes {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  #dscribe .icon_heart {
    width: 16px;
    height: 14px;
    background-image: url(../../../public/images/lp/icons/icon_heart.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  #dscribe .numbers_likes {
    font-size: 14px;
  }

  #dscribe .from {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  #dscribe .text_from {
    font-size: 12px;
  }

  #dscribe .from_name {
    font-size: 15px;
  }

  /* modal */
  .modal-overlay {
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
  }

  .modal-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  .modal-open {
    display: block;
  }

  .box01 {
    background-color: #ccc;
    height: 200px;
    margin: 0 auto;
    width: 80%;
  }

  .box02 {
    background-color: #ccc;
    height: 200px;
    margin: 0 auto;
    width: 80%;
  }

  @keyframes mmfadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes mmfadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes mmslideIn {
    from {
      transform: translateY(15%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes mmslideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-10%);
    }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .modal-overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .modal-container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal-overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal-container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  .micromodal-slide .modal-container,
  .micromodal-slide .modal-overlay {
    will-change: transform;
  }

  /*---------------------------------------------------------------*\
  $header
  \*---------------------------------------------------------------*/

  header {
    width: calc(100% - 36px);
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    margin: 0 18px;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  header .header__logo {
    width: 110px;
    position: absolute;
    left: 0;
  }

  header .header__menu {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    gap: 10px;
  }

  header .each_language {
    font-size: 13px;
    text-decoration: underline !important;
    cursor: pointer;
  }

  header .divide {
    height: 10px;
    width: 1px;
  }

  header .search,
  header .notification {
    width: 24px;
    margin-right: 24px;
  }

  header .my_menu {
    width: 24px;
    border-radius: 50%;
    overflow: hidden;
    border: solid 1px #fff;
  }

  /*---------------------------------------------------------------*\
  $firstview
  \*---------------------------------------------------------------*/

  #firstview {
    padding: 26px 0 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  #firstview .content_firstview {
    width: calc((100% - 36px));
    margin: 0 auto;
  }

  #firstview .bg_firstview {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 560px;
    position: absolute;
    top: 0;
    left: 0;
  }

  #firstview .bg_firstview_jp {
    background-image: url(../../../public/images/lp/background_jp.webp);
  }

  #firstview .bg_firstview_en {
    background-image: url(../../../public/images/lp/background_01.webp);
  }

  #firstview .bg_firstview_en:after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: block;
    content: "";
    border: none;
  }

  #firstview .signup {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 120px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 80px;
    font-size: 13px;
  }

  /*---------------------------------------------------------------*\
  $concept
  \*---------------------------------------------------------------*/

  #concept {
    padding: 20px 0px 48px;
    border-radius: 32px 32px 0 0;
    z-index: 2;
    margin: 0px auto 0;
    overflow: hidden;
  }

  /* slogan */
  #concept .texts_concept {
    display: flex;
    width: 86%;
    flex-direction: column;
    gap: 16px;
    margin: 4px auto 0;
  }

  #concept .wrap_logo {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  #concept .border {
    width: 18px;
    height: 1px;
  }

  #concept .logo {
    width: 90px;
  }

  #concept .logo img {
    width: 100%;
  }

  #concept .introduction {
    font-size: 16px;
  }

  /* slider */
  #concept .text_card_tap {
    width: 115px;
    margin: 0 auto 14px;
  }

  #concept .dscribeCards .swiper-slide {
    width: 300px !important;
  }

  /*---------------------------------------------------------------*\
  $ask
  \*---------------------------------------------------------------*/

  #ask {
    overflow: hidden;
  }

  #ask .bubble {
    font-size: 36px;
    letter-spacing: -1.5px;
    margin: 80px 0 0 0;
  }

  #ask .then {
    font-size: 17px;
    padding: 0 40px;
    text-align: center;
    margin: 16px auto 40px;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /*---------------------------------------------------------------*\
  $animation UI - common
  \*---------------------------------------------------------------*/

  .swiperUI {
    height: 720px;
    width: 90%;
    margin: 0 auto 40px;
  }

  /*---------------------------------------------------------------*\
  $animation UI:#1 - Ask Dscribe me
  \*---------------------------------------------------------------*/

  .screen_ask_dscribeMe {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: solid 1px #eee;
    width: 90%;
    background-image: url(../../../public/images/lp/background_02.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }

  .screen_ask_dscribeMe:after {
    display: block;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .screen_ask_dscribeMe .wrap_button {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .screen_ask_dscribeMe .notes {
    width: calc((100% - 110px));
    font-size: 11px;
  }

  .screen_ask_dscribeMe .myPage__layer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    height: 580px;
    background-color: #fff;
    margin: 0px auto;
    padding: 32px 18px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  /* invited from someone */
  .screen_ask_dscribeMe .myPage__requesterIndication {
    width: 95%;
    position: relative;
    z-index: 1;
    margin: auto;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .screen_ask_dscribeMe .myPage__requesterIndication .logo {
    width: 152px;
    margin: 0 auto;
  }

  .screen_ask_dscribeMe .myPage__requesterIndication .user_name {
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  .screen_ask_dscribeMe .myPage__requesterIndication .thumbnail {
    width: 27px;
    height: 27px;
    border-radius: 40px;
    overflow: hidden;
    border: solid 1px #fff;
    margin: 0 8px;
  }

  .screen_ask_dscribeMe .myPage__requesterIndication .name {
    font-size: 16px;
  }

  /* my page - profile */
  .screen_ask_dscribeMe .myPage__thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: -100px auto 0;
    border: solid 1px #fff;
  }

  .screen_ask_dscribeMe .myPage__userName {
    font-size: 18px;
    margin-top: 8px;
  }

  .screen_ask_dscribeMe .myPage__userId {
    font-size: 12px;
    margin-top: 4px;
  }

  .screen_ask_dscribeMe .myPage__selfIntroduction {
    font-size: 13px;
    margin: 12px auto;
  }

  /* typing animation */
  #typing {
    opacity: 0;
    transition: all 0.3s;
    z-index: 3;
    font-size: 14px;
    line-height: 1.4;
    margin: 8px 0 0 0;
  }

  .typing {
    opacity: 1;
    animation: fade 0.4s ease-in-out 0.3s 1 normal forwards;
  }

  /* send button animation */
  #send {
    width: 100px;
    height: 36px;
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .send {
    animation: send 1s ease-in-out 1s 1 normal forwards;
  }

  @keyframes send {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  /*---------------------------------------------------------------*\
  $animation UI#2: Arriving Dscription
  \*---------------------------------------------------------------*/

  .arriving_dscription {
    background-color: #f8f8f8;
    border: solid 1px #eee;
    border-radius: 10px;
    overflow: hidden;
  }

  .arriving_dscription .header {
    padding: 0 16px;
    height: 64px;
    justify-content: space-between;
    display: flex;
  }

  .arriving_dscription .header .header__logo {
    width: 118px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .arriving_dscription .header .header__menu {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .arriving_dscription .header .search,
  .arriving_dscription .header .notification {
    width: 24px;
  }

  .arriving_dscription .header .my_menu {
    width: 28px;
    border-radius: 50%;
    overflow: hidden;
    border: solid 1px #fff;
  }

  .arriving_dscription .main {
    height: calc((100% - 64px));
    border-radius: 32px 32px 0 0;
  }

  .arriving_dscription .user {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px 20px 0;
  }

  .arriving_dscription .info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 16px 10px 0 0;
    width: calc((100% - 120px));
  }

  .arriving_dscription .name {
    font-size: 20px;
  }

  .arriving_dscription .description {
    font-size: 13px;
    line-height: 1.3;
  }

  .arriving_dscription .num_users {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .arriving_dscription .icon_user {
    display: flex;
    align-items: center;
  }

  .arriving_dscription .each_icon_user {
    background-color: #f7f7f7;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
  }

  .arriving_dscription .each_icon_user:nth-of-type(2),
  .arriving_dscription .each_icon_user:nth-of-type(3) {
    margin-left: -8px;
  }

  .arriving_dscription .number {
    font-size: 12px;
  }

  .arriving_dscription .thumbnail {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #f5f5f5;
  }

  .arriving_dscription .my_dscription {
    padding: 0 16px;
    margin: 30px 0 0 0;
  }

  .arriving_dscription .headline {
    font-size: 18px;
  }

  .arriving_dscription .card_area {
    margin: 20px 0 0 0;
  }

  /* link card */
  .arriving_dscription .link_card_area {
    padding: 20px 20px 0;
  }

  .arriving_dscription .picture_card {
    border-radius: 5px;
    overflow: hidden;
    height: 160px;
  }

  .arriving_dscription .subject {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-size: 12px;
    padding: 6px 7px;
    border-radius: 4px;
  }

  .arriving_dscription .picture_card img {
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
  }

  /* card */
  #card {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .card {
    animation-duration: calc(1s * 0.75);
    animation-duration: calc(var(--animate-duration) * 0.75);
    animation: card 1s ease-in-out 1s 1 normal forwards;
  }

  @keyframes card {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  /*---------------------------------------------------------------*\
  $back
  \*---------------------------------------------------------------*/

  #back {
    padding: 60px 0;
  }

  #back .subtitle {
    font-size: 17px;
  }

  #back .title {
    font-size: 32px;
    letter-spacing: -1.5px;
    margin: 8px 0 0 0;
  }

  /* animation UI#3 - Dscribe each other */
  #back .swiperUI_02 {
    width: 90%;
    height: 660px;
    margin: 40px auto 0;
  }

  #back .swiper-slide {
    border-radius: 10px !important;
    overflow: hidden !important;
  }

  #back .each_bar {
    width: calc((100%) / 2);
  }

  /*---------------------------------------------------------------*\
  $ready
  \*---------------------------------------------------------------*/

  #ready {
    padding: 100px 0;
    border-radius: 32px 32px 0 0;
    overflow: hidden;
    margin: 100px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  #ready .bg_footer {
    background-image: url(../../../public/images/lp/background_01.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 570px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    right: 0;
    left: 0;
  }

  #ready:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 0;
    left: 0;
  }

  #ready .catch {
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -1.5px;
    text-shadow: #000 0 0 30px;
  }

  #ready .button {
    width: 340px;
    height: 64px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /*---------------------------------------------------------------*\
  $footer
  \*---------------------------------------------------------------*/

  footer {
    background-color: #0f0f0f;
    padding: 140px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  footer .logo {
    width: 160px;
  }

  footer .content {
    gap: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer .each_menu > a {
    font-size: 14px;
  }

  footer .copyright {
    font-size: 10px;
  }

  footer .icons_sns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  footer .icon_instagram {
    width: 24px;
    height: 24px;
    background-image: url("../../../public/images/lp/icon_instagram_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  footer .icon_x {
    width: 24px;
    height: 24px;
    background-image: url("../../../public/images/lp/icon_x_white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  footer .note {
    width: 220px;
    font-size: 10px;
  }

  @media screen and (min-width: 1201px) {
  }

  @media screen and (min-width: 1081px) {
  }

  @media screen and (min-width: 861px) {
  }

  @media screen and (min-width: 601px) {
  }
}
