@charset "UTF-8";

#lp {
  #dscribe .each_bar {
    width: calc((100%) / 3) !important;
  }

  header {
    height: 50px !important;
  }

  #firstview {
    padding: 40px 0 32px !important;
  }

  #concept .introduction {
    font-size: 15px !important;
  }

  #ask .then {
    font-size: 15px !important;
  }

  #back .then {
    font-size: 15px !important;
    padding: 0 40px !important;
    text-align: center !important;
    margin: 16px auto 40px !important;
  }

  #ready .catch {
    font-size: 26px !important;
    line-height: 1.3 !important;
  }

  footer .each_menu > span {
    font-size: 13px !important;
    line-height: 14px;
  }
}
